<template>
  <div>
    Callback
  </div>
</template>

<script>
export default {
  name: 'Callback',
  mounted() {
    console.log('code', this.$route.query.code);
    console.log('state', this.$route.query.state);
    console.log('valid', this.$route.query.state === localStorage.getItem('state') ? true : false);
    console.log('error', this.$route.query.error);
    this.$store.dispatch('auth/getTokens', this.$route.query.code);
  }
}
</script>

<style>

</style>